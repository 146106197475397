import React from "react"
import PropTypes from "prop-types"
import Columns from "./columns"
import Column from "./column"
import NextButton from "./next-button"
import Heading from "./heading"

const CourseFooterNext = ({ title, label, ...props }) => {
  return (
    <Columns
      space={[6, 12]}
      alignY="center"
      alignX="right"
      collapse={[true, false]}
    >
      {title && (
        <Column width="content">
          <Heading level={6}>{title}</Heading>
        </Column>
      )}
      <Column width="content">
        <NextButton {...props}>{label}</NextButton>
      </Column>
    </Columns>
  )
}

CourseFooterNext.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
}

CourseFooterNext.defaultProps = {
  label: "Weiter",
}

export default CourseFooterNext
