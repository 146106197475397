/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Box from "./box"
import Icon from "./icon"
import LoadingSpinner from "./loading-spinner"

const NextButton = ({
  icon,
  children,
  isDisabled,
  isLoading,
  color,
  type,
  href,
  onClick,
  to,
  ...restProps
}) => {
  const _isInteractive = !isDisabled && !isLoading

  return (
    <Box
      as={
        type === "submit"
          ? "button"
          : to
          ? Link
          : href
          ? "a"
          : onClick
          ? "button"
          : "span"
      }
      type={type || null}
      to={to || null}
      href={href || null}
      onClick={onClick || null}
      sx={{
        appearance: "none",
        bg: !_isInteractive ? "disabled" : "black",
        cursor: !_isInteractive ? "not-allowed" : "pointer",
        textAlign: "center",
        color: !_isInteractive ? "whisper" : "white",
        fontSize: [2, 3, 4],
        fontFamily: "sans",
        fontWeight: "bold",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        ":hover, :focus": !_isInteractive
          ? null
          : {
              bg: "primary",
              color: "black",
            },
      }}
      {...restProps}
    >
      <Box px={6} py={3}>
        {children}
      </Box>
      <Box bg={!_isInteractive ? "disabled" : "primary"} p={3}>
        {isLoading ? (
          <LoadingSpinner sx={{ width: [6, 8], height: [6, 8] }} />
        ) : (
          <Icon
            icon={icon}
            size={[6, 8]}
            color={!_isInteractive ? "whisper" : "black"}
          />
        )}
      </Box>
    </Box>
  )
}

NextButton.displayName = "NextButton"

NextButton.propTypes = {
  color: PropTypes.string,
  href: PropTypes.string,
  isDisabled: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
}

NextButton.defaultProps = {
  color: "primary",
  icon: "arrow-right",
}

export default NextButton
